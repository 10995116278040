import { setCookie } from './handleCookies';

export const followUserMail = () => {
    window.addEventListener('input', e => {
        const { target } = e;

        if (target.getAttribute('type') === 'email') {
            setCookie('mda_user_email', target.value, 365);
        }
    });
};
