import { addToDatalayer } from './addToDatalayer';
import { readCookie, setCookie } from './handleCookies';

export const handleDataLayer = () => {
    const links = document.querySelectorAll('a');
    if (links.length > 0) {
        links.forEach(link => {
            link.addEventListener('click', e => {
                if (link.hasAttribute('download')) {
                    const url = new URL(link.href);
                    const fileName = url.pathname.split('/').pop();
                    const fileExtension = fileName.includes('.')
                        ? fileName.split('.').pop()
                        : '';
                    addToDatalayer({
                        event: 'file_download',
                        file_extension: fileExtension,
                        file_name: fileName,
                        link_classes: link.className,
                        link_id: link.id,
                        link_text: link.textContent.trim(),
                        link_url: link.href,
                    });
                } else {
                    addToDatalayer({
                        event: 'click_out',
                        link_classes: link.className,
                        link_domain: new URL(link.href).hostname,
                        link_id: link.id,
                        link_url: link.href,
                        outbound:
                            new URL(link.href).hostname !==
                            window.location.hostname,
                    });
                }
            });
        });
    }

    const hasBeenOnSite = readCookie('hasBeenOnSite');

    if (!hasBeenOnSite) {
        addToDatalayer({
            event: 'first_visit',
        });
        setCookie('hasBeenOnSite', 1, 9999);
    }

    window.addEventListener('click', e => {
        const { target } = e;
        if (target.getAttribute('data-add-to-dl') == '') {
            const event = target.getAttribute('data-dl-event');
            const title = target.getAttribute('data-dl-title');
            const value = target.getAttribute('data-dl-value');
            const email = target.getAttribute('data-dl-email');
            const type = target.getAttribute('data-dl-type');
            const date = target.getAttribute('data-dl-date');

            const dataLayerObject = {
                event: event ?? null,
                email: email ?? null,
                title: title ?? null,
                date: date ?? null,
                value: value ?? 0,
            };

            if (type) {
                dataLayerObject.type = type;
            }

            window.dataLayer.push(dataLayerObject);
        }
    });
};
